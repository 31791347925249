// const $ = require('jquery');
const Swiper = require('swiper');

class GallerySlider {
	constructor() {
		this.galleries = $('.wp-block-gallery.is-style-slider');
		this.init();
	}

	init() {
		this.galleries.each(function (i, node) {
			// Detect column number
			const classStr = node.getAttribute('class');
			let columns = classStr.replace(/.*columns-(\d).*/, '$1');
			if (!columns) columns = 1;

			// Attach/Remove class
			$(node).addClass('swiper-container').removeClass('columns-' + columns);
			$(node).find('.blocks-gallery-grid').addClass('swiper-wrapper');
			$(node).find('.blocks-gallery-item').addClass('swiper-slide');

			// Clone thumbs
			const $thumbs = $(node).clone();
			$(node).after($thumbs);
			$thumbs.addClass(['gallery-thumb']);
			$(node).addClass(['gallery-top']);

			// Init Swiper
			let galleryThumbs = new Swiper($thumbs.get(0), {
				spaceBetween: 25,
				slidesPerView: columns,
				loop: true,
				freeMode: true,
				loopedSlides: columns + 1,
				watchSlidesVisibility: true,
				watchSlidesProgress: true,
			});

			let galleryTop = new Swiper(node, {
				// spaceBetween: 10,
				loop: true,
				loopedSlides: columns + 1,
				thumbs: {
					swiper: galleryThumbs
				},
			});
		});
	}
}
module.exports = GallerySlider;