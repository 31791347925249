const GallerySlider = require('./_GallerySlider.js');

(function($) {

	$.fn.fixedscroll = function(){
		this.each(function(){
			var header = $('header');
			var position = $('header').offset().top + 50;
			$(this).on('load scroll resize', function(){
				if($(this).scrollTop() > position && header.hasClass('is-fixed') == false) {
					header.addClass('is-fixed');
				}
				else if($(this).scrollTop() < position && header.hasClass('is-fixed') == true){
					header.removeClass('is-fixed');
				}
			});
		});
		return this;
	};

	function fix_element(){
		var margin = 100;
		var fixnav = $('.fix-nav');
		var fix = $('.fix-element');
		var fixrange = $('.fix-range');

		var fixH = fix.height();
		var fixnavY = fixrange.offset().top;
		var fixnavH = fixrange.height();//260
		var targetScroll = fixnavY-margin;
		var windowScroll = $(window).scrollTop();

		if(windowScroll > targetScroll){
			if(windowScroll > fixnavY+fixnavH-fixH-margin){
				fix.css({position:'absolute', top:fixnavH-fixH-margin});
			}else{
				fix.css({position:'fixed', top:0});
			}
		}else{
			fix.css({position:'absolute', top:0});
		}
	}

	var navLink = $('.fix-nav .nav-side li a');
	var contentsArr = new Array();
	for(var i = 0; i < navLink.length; i++){
			var targetContents = navLink.eq(i).attr('href');
			if(targetContents.charAt(0) == '#') {
				var targetContentsTop = $(targetContents).offset().top;
				var targetContentsBottom = targetContentsTop + $(targetContents).outerHeight(true) - 1;
				contentsArr[i] = [targetContentsTop, targetContentsBottom];
			}
	};
	function currentCheck() {
		var windowScrolltop = $(window).scrollTop();
		for (var i = 0; i < contentsArr.length; i++){
			if(contentsArr[i][0] <= windowScrolltop + 200 && contentsArr[i][1] >= windowScrolltop){
				navLink.removeClass('current');
				navLink.eq(i).addClass('current');
				$('.nav-image-list li').removeClass('current');
				$('.nav-image-list li').eq(i).addClass('current');
				i == contentsArr.length;
			}
		};
	}

	$(function(){

		var headerHeight = $('.header-section').outerHeight();
		var urlHash = location.hash;
		if(urlHash) {
			$('body,html').stop().scrollTop(0);
			setTimeout(function(){
				var target = $(urlHash);
				var position = target.offset().top - headerHeight;
				$('body,html').stop().animate({scrollTop:position}, 500);
			}, 200);
		}
		$('a[href^="#"]').not('.nav-side li a').on('click', function(){
			var href= $(this).attr("href");
			var target = $(href);
			var position = target.offset().top - headerHeight - 100;
			$('body,html').stop().animate({scrollTop:position}, 500);
			return false;
		});

		$('#btn-nav').on('click', function(){
			$('body').toggleClass('open');
		});
		$('#global-nav a[href]').on('click', function(event){
			$('#btn-nav').trigger('click');
		});

		$(window).fixedscroll();
		$('a[href^=http]').not('.fc-event').not('[href*="'+location.hostname+'"]').attr('target', '_blank').addClass('blank');
		$('.wp-block-file a').attr('target', '_blank');


		if($('.front-page .front-visual').length){
			function showtime(){
				var date = new Date();
				var ampm = '';
				var h = date.getHours();
				var m = date.getMinutes();
				if(h>=12){
					ampm = 'pm';
					h = h - 12;
				}else {
					ampm = 'am';
				}
				var hh = ('0' + h).slice(-2);
				var mm = ('0' + m).slice(-2);
				$('#datetime').text(hh+':'+mm+' '+ampm);
			}
			setInterval(showtime,1000);
		}

		if($('.entry-content .fix-range').length){
			$(window).on('load scroll resize', function() {
				fix_element();
				currentCheck();
			});
			navLink.click(function() {
				$('html,body').animate({
						scrollTop: $($(this).attr('href')).offset().top-50
					}, 300);
					return false;
			});
		}

		if($('.is-style-slider-simple').length){
			$('.is-style-slider-simple > ul').addClass('swiper-wrapper');
			$('.is-style-slider-simple > ul li').addClass('swiper-slide');
		}
		var swiperBasic = $('.is-style-slider-simple');
		var swiper = new Swiper(swiperBasic,{
			loop: true,
			speed: 600,
			autoplay:{delay: 3000}
		});


	new GallerySlider();


	});

})(jQuery);